import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Chromelp from "src/components/postchromelp"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_cb from "src/components/btf_cb"


const chromelpData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
         body {
          min-height: 100vh;
          width: 100%;
          min-width: 728px;
          min-height: 700px;
          background: #E1EEF8;
          background: linear-gradient(to top right, #E1EEF8, #F7FCFF);
          padding: 0;
          margin: 0;
          background-repeat: no-repeat;
          background-size: cover;
          font-family: 'Roboto', sans-serif;
        }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Chromelp data={chromelpData}></Chromelp>
      <Btf_cb data={btfData}></Btf_cb>
      </section>
    </HomepageLayout>
  )
}
