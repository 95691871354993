import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/postchromelp.module.scss"
import Data from "./data/postchromelp.json"
import Footerlinks from "@tightrope/footerlinks/footer"


class postchromelp extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isChrome: isChrome(),
        isFirefox: isFirefox(),
        isIeEdge: isIeEdge(),
        display: 'none',
        pageData: props.data,
        data: Data
      }
    }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    if(this.props.data){
      this.props.data.then(data => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }

      });
    }
  }
  render() {
    return (
      <>
      <div id = {Styles.postchromelp}>
        <div className = {Styles.header}><img src="/img/clear.png" alt="" /></div>
        <div id = {Styles.flow1}>
     <div className = {Styles.main}>
       <h1 className = "hc_param">{this.state.data.headline}</h1>

       <div className = {Styles.stepGrid}>
         <div className = {Styles.step}>1</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead1}</h2>
           <p>{this.state.data.copy1}</p>
         </div>

         <div className = {Styles.step}>2</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead2}</h2>
           <p>{this.state.data.copy2}</p>
         </div>
       </div>
       <div className = {Styles.comp}>{this.state.data.compliance}</div>
       <div className = {Styles.customButton} data-cy="cta" onClick = {() => window.triggerInstall()}>
       <div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
      <p className = {Styles.fadeCTA}>DOWNLOAD NOW</p>
       </div>
     <div className = {Styles.disclosure}>By clicking above you agree to the <a href="https://clearbar.app/terms-of-service" target="_blank">Terms of Service</a> and <a href="https://clearbar.app/privacy-policy" target="_blank">Privacy Policy</a> for Clear, a Chromium based browser with web search results provided by Yahoo.</div>
       </div>
        </div>
        <div id = {Styles.flow2}>
        <h3>Easy to Install</h3>
		<div className = {Styles.slider}>
			<div className = {Styles.slide}>
			<p>1. Click on the download file</p><img alt="" src="/img/slide1.jpg" /></div>
			<div className = {Styles.slide}>
			<p>3. Enjoy effortless web page printing.</p><img alt="" src="/img/slide3.png" /></div>
			<div className = {Styles.slide}>
			<p>2. Follow the installation instructions</p><img alt="" src="/img/slide2.jpg" /></div>
		</div>
		<div className = {Styles.chevContainer}>
			<div className = {Styles.chevron}></div>
			<div className = {Styles.chevron}></div>
			<div className = {Styles.chevron}></div>
		</div>
        </div>
        </div>
      </>
    )
  }
}
export default postchromelp
